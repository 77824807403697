import { ReportInterface } from "../../middleware/interfaces/report/report-interface";

export const ServerResponseToReport = (serverResponse: object) => {
  const report: ReportInterface = serverResponse as ReportInterface;
  report.reportElements?.forEach((reportElement) => {
    if (!reportElement.reportElementGroupName) {
      // console.warn(
      //   "reportElementGroupName is null or undefined",
      //   reportElement.reportElementName
      // );
      return;
    }

    if (!report.reportContentFilter) {
      report.reportContentFilter = {
        reportElementGroupData: [],
        type: "ReportContentFilterInterface",
      };
    }

    const existingGroup =
      report.reportContentFilter.reportElementGroupData.find(
        (element) =>
          element.reportElementGroupName ===
          reportElement.reportElementGroupName
      );

    if (existingGroup) {
      existingGroup.reportElementGroupData.reportElementGroupCount += 1;
    } else {
      report.reportContentFilter.reportElementGroupData.push({
        reportElementGroupName: reportElement.reportElementGroupName,
        reportElementGroupData: {
          reportElementGroupCount: 1,
          reportElementGroupFilter: true,
          reportElementGroupColor: reportElement.reportElementColor,
        },
      });
    }
  });

  return report;
};
