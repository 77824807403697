import { Dispatch, FC, useState, useEffect, useCallback } from "react";
import { ReportContentFilterInterface } from "../../../middleware/interfaces/report/report-content-filter_interface";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { StopEventPropagation } from "../../../helper/ui/methods";

interface ReportContentFilterDialogProps {
  reveal: boolean;
  state: StateInterface;
  setReportContentfilterDialogReveal: Dispatch<React.SetStateAction<boolean>>;
  dispatch: Dispatch<any>;
}

const ReportContentFilterDialog: FC<ReportContentFilterDialogProps> = ({
  reveal,
  state,
  setReportContentfilterDialogReveal,
  dispatch,
}) => {
  const [updatedReportContentfilter, setUpdatedReportContentfilter] =
    useState<ReportContentFilterInterface>();

  useEffect(() => {
    if (state.openReport?.reportContentFilter) {
      setUpdatedReportContentfilter(state.openReport?.reportContentFilter);
    }
  }, [state.openReport]);

  const HandleCancelClick = useCallback(() => {
    setReportContentfilterDialogReveal(false);
  }, [setReportContentfilterDialogReveal]);

  const HandleApplyClick = useCallback(() => {
    const filteredReport = {
      ...state.openReport,
      reportContentFilter: updatedReportContentfilter,
    };

    dispatch({
      type: "FILTER_REPORT",
      payload: filteredReport,
    });
    setReportContentfilterDialogReveal(false);
  }, [
    dispatch,
    setReportContentfilterDialogReveal,
    updatedReportContentfilter,
    state.openReport,
  ]);

  const HandleCheckboxChange = (index: number) => {
    if (!updatedReportContentfilter) return;

    const updatedGroupData = [
      ...updatedReportContentfilter.reportElementGroupData,
    ];
    updatedGroupData[index].reportElementGroupData.reportElementGroupFilter =
      !updatedGroupData[index].reportElementGroupData.reportElementGroupFilter;

    setUpdatedReportContentfilter({
      ...updatedReportContentfilter,
      reportElementGroupData: updatedGroupData,
    });
  };

  if (!reveal) {
    return null;
  }

  return (
    <div className="dialogCover" onDoubleClick={StopEventPropagation}>
      <div className="dialog">
        <h2 className="dialogTitle">Report Content Filter</h2>
        <div id="ReportContentFilterWrapper">
          {updatedReportContentfilter!.reportElementGroupData.map(
            (group, index) => (
              <div className="ReportContentFilter" key={index}>
                <div
                  className="ReportContentFilterColor"
                  style={
                    {
                      "--reportContentFilterColor":
                        group.reportElementGroupData.reportElementGroupColor.startsWith(
                          "#"
                        )
                          ? group.reportElementGroupData.reportElementGroupColor
                          : "#" +
                            group.reportElementGroupData
                              .reportElementGroupColor,
                    } as React.CSSProperties
                  }
                />
                <span className="ReportContentFilterLable">
                  {group.reportElementGroupName} |{" "}
                  {group.reportElementGroupData.reportElementGroupCount}
                </span>
                <input
                  className="ReportContentFilterCheckbox"
                  type="checkbox"
                  checked={
                    group.reportElementGroupData.reportElementGroupFilter
                  }
                  onChange={() => HandleCheckboxChange(index)}
                />
              </div>
            )
          )}
        </div>
        <div className="dialogButtonWrapper">
          <input
            type="button"
            name="apply"
            value="Apply"
            onClick={HandleApplyClick}
          />
          <input
            type="button"
            name="cancel"
            value="Cancel"
            onClick={HandleCancelClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportContentFilterDialog;
