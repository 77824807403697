import { FC, useState } from "react";
import { ReportElementInterface } from "../../../middleware/interfaces/report/report-element-interface";

interface reportElementProps {
  reportElement: ReportElementInterface;
  userInputElementCount: number;
  filter: boolean;
}

const ReportElement: FC<reportElementProps> = ({
  reportElement,
  userInputElementCount: userInputElementCount,
  filter,
}) => {
  const [reportImageMaximize, setReportImageMaximize] = useState(false);
  const [repportDataMaximize, setReportDataMaximize] = useState(false);

  const replacements = ["|", ":"];

  const SetReportElementStyle = () => {
    return {
      "--reportElementColor": reportElement.reportElementColor.startsWith("#")
        ? reportElement.reportElementColor
        : "#" + reportElement.reportElementColor,
      "--reportElementWidth": `calc(${100 / userInputElementCount}% - 2%)`,
    } as React.CSSProperties;
  };

  const SetReportElementBodyStyle = () => {
    const isImageMax = reportImageMaximize && !repportDataMaximize;
    const isDataMax = !reportImageMaximize && repportDataMaximize;

    return {
      "--reportElementBodyMaxHeight":
        isImageMax || !repportDataMaximize ? "100%" : "100%",
      "--reportElementBodyHeight":
        isDataMax || !reportImageMaximize ? "100%" : "100%",
    } as React.CSSProperties;
  };

  const SetReportElementImageStyle = () => {
    if (reportImageMaximize) {
      return {
        "--reportElementImageWidth": "100%",
      } as React.CSSProperties;
    } else {
      return {
        "--reportElementImageWidth": "50%",
      } as React.CSSProperties;
    }
  };

  const SetReportElementDataStyle = () => {
    if (repportDataMaximize) {
      return {
        "--reportElementDataWidth": "100%",
      } as React.CSSProperties;
    } else {
      return {
        "--reportElementDataWidth": "50%",
      } as React.CSSProperties;
    }
  };

  const HandleReportElementImageDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setReportImageMaximize(!reportImageMaximize);
  };

  const HandleReportElementDataDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setReportDataMaximize(!repportDataMaximize);
  };

  return (
    <>
      {!filter ? (
        <></>
      ) : (
        <div
          className="reportElement"
          key={reportElement.reportElementID}
          style={SetReportElementStyle()}
        >
          {!reportImageMaximize && !repportDataMaximize && (
            <div className="reportElementHeader">
              <span>{reportElement.reportElementName}</span>
            </div>
          )}
          <div
            className="reportElementBody"
            style={SetReportElementBodyStyle()}
          >
            {!repportDataMaximize && (
              <div
                className="reportElementImage"
                onDoubleClick={HandleReportElementImageDoubleClick}
                style={SetReportElementImageStyle()}
              >
                <img
                  src={reportElement.reportElementImageUrl}
                  alt={`Report ${reportElement.reportElementID.toString()}`}
                />
              </div>
            )}
            {!reportImageMaximize && (
              <div
                className="reportElementData"
                onDoubleClick={HandleReportElementDataDoubleClick}
                style={SetReportElementDataStyle()}
              >
                <div className="reportElementComments">
                  {reportElement.reportElementComments &&
                    reportElement.reportElementComments.trim() !== "" &&
                    reportElement.reportElementComments
                      .split("<br>")
                      .map((comment, index) => {
                        replacements.forEach((replacement) => {
                          comment = comment.replace("/--/", replacement);
                        });
                        return comment.trim() !== "" ? (
                          <span key={index}>{comment}</span>
                        ) : (
                          <></>
                        );
                      })}
                </div>
                <div className="reportElementReviewTexts">
                  {reportElement.reportElementReviewTexts &&
                    reportElement.reportElementReviewTexts.trim() !== "" &&
                    reportElement.reportElementReviewTexts
                      .split("<br>")
                      .map((reviewText, index) =>
                        reviewText.trim() !== "" ? (
                          <span key={index}>{reviewText}</span>
                        ) : (
                          <></>
                        )
                      )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ReportElement;
