import { FC, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  HandleDragOver,
  HandleDragLeave,
  HandleDrop,
  SaveUserToLocalStorage,
  UpdateUserLastActionDate,
  HandleLogOut,
  HandleReturn,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import FileInfo from "../ui/file/file-info";
import ResponseDialog from "../ui/user/response-dialog";
import DecisionDialog from "../ui/user/binary-decision-dialog";
import ViewerOptionDialog from "../ui/user/viewer-option-dialog";
import MenuButton from "../ui/user/menu-button";
import { FileInterface } from "../../middleware/interfaces/file/file-interface";
import FolderDialog from "../ui/folder/folder-dialog";
import { FolderInterface } from "../../middleware/interfaces/folder/folder-interface";
import FolderInfo from "../ui/folder/folder-info";
import ProjectContent from "../ui/project/project-content";
import FileVersionDialog from "../ui/file/file-version-dialog";
import "../../styles/project-page.css";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";
import ReportInfo from "../ui/report/report-info";
import { ReportInterface } from "../../middleware/interfaces/report/report-interface";
import { RetrieveModelInterface } from "../../middleware/interfaces/file/retrieve-model-interface";
import ProjectContentFilterDialog from "../ui/project/project-content-filter-dialog";
import ReportDescriptionDialog from "../ui/report/report-description-dialog";

export const ProjectPage: FC = () => {
  const [state, dispatch] = useAppStateContext();
  const [selectedContent, setSelectedContent] = useState<
    FolderInterface | FileInterface | ReportInterface | null
  >(null);
  const [isHovered, setIsHovered] = useState(false);
  // const [shareProjectDialogReveal, setShareProjectDialogReveal] =
  //   useState(false);
  const [
    projectContentfilterDialogReveal,
    setProjectContentfilterDialogReveal,
  ] = useState(false);
  const [folderDialogReveal, setFolderDialogReveal] = useState("");
  const [fileVersionDialogReveal, setFileVersionDialogReveal] = useState(false);
  useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    setSelectedContent(null);
    return () => {
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, [state.user]);

  useEffect(() => {
    if (state.decision) {
      if (state.decision.result !== "") {
        switch (state.decision.result) {
          case "DELETE_FOLDER":
          case "DOWNLOAD_FOLDER":
          case "EDIT_FOLDER":
          case "DELETE_FILE":
          case "DOWNLOAD_FILE":
          case "DELETE_REPORT":
          case "DOWNLOAD_REPORT":
            dispatch({
              type: state.decision.result,
              payload: state.decision.data,
            });
            if (
              state.decision.result === "DELETE_FILE" ||
              state.decision.result === "DELETE_FOLDER" ||
              state.decision.result === "DELETE_REPORT"
            ) {
              setSelectedContent(null);
            }
            break;
          case "NO_ACTION":
            break;
          case "STANDARD":
            dispatch({
              type: "RETRIEVE_MODEL",
              payload: {
                ...selectedContent,
                type: "RetrieveModelInterface",
              } as RetrieveModelInterface,
            });
            navigate("/STANDARD");
            break;
          case "4D":
          case "MAP":
          default:
            // Handle unknown action
            console.log(`Unknown decision result: ${state.decision.result}`);
        }
        dispatch({ type: "DECISION", decision: null });
      }
    }
  }, [state.decision?.result]);

  useEffect(() => {
    if (state.openReport) {
      const updatedUser = state.user;
      updatedUser!.openReport = state.openReport.reportID;
      dispatch({
        type: "UPDATE_USER",
        payload: updatedUser,
        response: "",
      });
      navigate("/REPORT_VIEWER");
    }
  }, [state.openReport]);

  useEffect(() => {
    if (!state.user?.openProject || !selectedContent) return;

    if (
      (selectedContent.type === "FolderInterface" &&
        !state.user.openProject.projectContentFilter?.folders) ||
      (selectedContent.type === "ReportInterface" &&
        !state.user.openProject.projectContentFilter?.reports) ||
      (selectedContent.type === "FileInterface" &&
        (((selectedContent as FileInterface).fileType === "ifc" &&
          !state.user.openProject.projectContentFilter?.filesAndModels
            ?.models) ||
          ((selectedContent as FileInterface).fileType !== "ifc" &&
            !state.user.openProject.projectContentFilter?.filesAndModels
              ?.files)))
    ) {
      setSelectedContent(null);
    }
  }, [state.user?.openProject?.projectContentFilter, selectedContent]);

  const fileConvasRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {(() => {
        if (state.user?.openProject) {
          return (
            <div
              id="pageWrapper"
              onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
            >
              <MainNavigationPanel user={state.user} />
              <div id="contentWrapper">
                <div id="leftMenu">
                  <div className="menuButtonWrapper">
                    <MenuButton
                      imageSource="assets/icons/add-folder.png"
                      alternativeText="ADD FOLDER"
                      dispatch={() => {
                        setFolderDialogReveal("CREATE");
                      }}
                    />
                    <MenuButton
                      imageSource="assets/icons/filter.png"
                      alternativeText="FILTER"
                      dispatch={() => {
                        setProjectContentfilterDialogReveal(true);
                      }}
                    />
                    <MenuButton
                      imageSource="assets/icons/return.png"
                      alternativeText="RETURN"
                      dispatch={() => {
                        // setReturnPressed(true);
                        HandleReturn(state, dispatch);
                      }}
                    />
                    <MenuButton
                      imageSource="assets/icons/logout.png"
                      alternativeText="LOG OUT"
                      dispatch={() => {
                        HandleLogOut(dispatch);
                      }}
                    />
                  </div>
                </div>
                <div id="content">
                  <div
                    id="projectCanvas"
                    className={isHovered ? "projectCanvasHover" : ""}
                    ref={fileConvasRef}
                    onDragOver={(event) => HandleDragOver(event, setIsHovered)}
                    onDrop={(event) => {
                      HandleDrop(
                        event,
                        state,
                        setIsHovered,
                        navigate,
                        dispatch
                      );
                    }}
                    onDragLeave={() => {
                      HandleDragLeave(setIsHovered);
                    }}
                    onClick={(e) => {
                      if (e.target === e.currentTarget) {
                        setSelectedContent(null);
                        setSelectedContent(null);
                      }
                    }}
                  >
                    <ProjectContent
                      user={state.user}
                      selectedContent={selectedContent}
                      setSelectedContent={setSelectedContent}
                    />
                  </div>
                  <ViewerOptionDialog
                    decision={state.decision}
                    dispatch={dispatch}
                  />
                  <FolderDialog
                    reveal={folderDialogReveal}
                    user={state.user}
                    setFolderDialogReveal={setFolderDialogReveal}
                    selectedFolder={selectedContent as FolderInterface}
                    dispatch={dispatch}
                  />
                  <DecisionDialog
                    decision={state.decision}
                    dispatch={dispatch}
                  />
                  <ResponseDialog
                    title="Server Response"
                    message={state.response}
                    dispatch={dispatch}
                  />
                  <FileVersionDialog
                    reveal={fileVersionDialogReveal}
                    selectedFile={selectedContent as FileInterface}
                    setSelectedFile={setSelectedContent}
                    setFileVersionDialogReveal={setFileVersionDialogReveal}
                  />
                  <ProjectContentFilterDialog
                    user={state.user}
                    reveal={projectContentfilterDialogReveal}
                    setProjectContentfilterDialogReveal={
                      setProjectContentfilterDialogReveal
                    }
                    dispatch={dispatch}
                  />
                  <ReportDescriptionDialog
                    user={state.user}
                    reportDescriptionObject={state.openReportDescription}
                    selectedReport={selectedContent as ReportInterface}
                    dispatch={dispatch}
                  />
                </div>
                <div id="rightMenu">
                  {/* <div className="menuButtonWrapper">
                    <MenuButton
                      imageSource="assets/icons/leave-project.png"
                      alternativeText="LEAVE PROJECT"
                      dispatch={() => {
                        console.log("LEAVE PROJECT");
                      }}
                    />
                    <MenuButton
                      imageSource="assets/icons/delete-project.png"
                      alternativeText="DELETE PROJECT"
                      dispatch={() => {
                        console.log("DELETE PROJECT");
                      }}
                    />
                  </div> */}
                  {selectedContent &&
                    (selectedContent.type === "FolderInterface" ? (
                      <FolderInfo
                        user={state.user}
                        selectedFolder={selectedContent}
                        setSelectedFolder={setSelectedContent}
                        setEditFolderDialogReveal={setFolderDialogReveal}
                        dispatch={dispatch}
                      />
                    ) : selectedContent.type === "FileInterface" ? (
                      <FileInfo
                        user={state.user}
                        selectedFile={selectedContent}
                        setFileVersionDialogReveal={setFileVersionDialogReveal}
                        dispatch={dispatch}
                      />
                    ) : selectedContent.type === "ReportInterface" ? (
                      <ReportInfo
                        user={state.user}
                        selectedReport={selectedContent}
                        setSelectedReport={setSelectedContent}
                        dispatch={dispatch}
                      />
                    ) : null)}
                </div>
              </div>
              <MainFooterPanel />
            </div>
          );
        } else {
          return <Navigate to={"/authentication&registration"} />;
        }
      })()}
    </>
  );
};
