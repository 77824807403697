import { FC, Dispatch, useState, useEffect } from "react";
import { BytesToMB, FormatDateFE } from "../../../helper/general/methods";
import { DecisionInterface } from "../../../middleware/interfaces/user/decision-interface";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { ReportInterface } from "../../../middleware/interfaces/report/report-interface";
import { ReportDeleteInterface } from "../../../middleware/interfaces/report/report-delete-interface";
import { DownloadReportInterface } from "../../../middleware/interfaces/report/report-download-interface";
import { ReportRetrieveInterface } from "../../../middleware/interfaces/report/report-retrieve-interface";
import { ReportRetrieveDescriptionInterface } from "../../../middleware/interfaces/report/report-retrieve-description-interface";

interface reportInfoProps {
  user: UserInterface;
  selectedReport: ReportInterface;
  setSelectedReport: Dispatch<any>;
  dispatch: Dispatch<any>;
}

const ReportInfo: FC<reportInfoProps> = ({
  user,
  selectedReport,
  setSelectedReport,
  dispatch,
}) => {
  const [openReport, setOpenReport] = useState(false);
  const [deleteReportDialogReveal, setDeleteReportDialogReveal] =
    useState(false);
  const [downloadReport, setDownloadReport] = useState(false);
  const [retrieveReportDescription, setRetrieveReportDescription] =
    useState(false);

  useEffect(() => {
    if (openReport === true) {
      const openReportData: ReportRetrieveInterface = {
        email: user.email,
        reportID: selectedReport.reportID,
        projectID: user.openProject?.projectID!,
        type: "ReportRetrieveInterface",
      };
      dispatch({
        type: "RETRIEVE_REPORT",
        payload: openReportData,
      });
      setOpenReport(false);
      setSelectedReport(null);
    }
  }, [openReport]);

  useEffect(() => {
    if (deleteReportDialogReveal) {
      const reportDeleteData: ReportDeleteInterface = {
        email: user.email,
        reportID: selectedReport.reportID,
        projectID: user.openProject?.projectID!,
        projectUserRole: user.openProject?.projectUserRole!,
        type: "ReportDeleteInterface",
      };
      const decision: DecisionInterface = {
        title: "Delete Report",
        message:
          "You have selected a report to delete. Are you sure you want to continue?",
        decisionOptions: ["DELETE_REPORT", "NO_ACTION"],
        result: "",
        data: reportDeleteData,
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setDeleteReportDialogReveal(false);
  }, [deleteReportDialogReveal]);

  useEffect(() => {
    if (retrieveReportDescription === true) {
      const reportRetrieveDescriptionData: ReportRetrieveDescriptionInterface =
        {
          reportID: selectedReport.reportID,
          type: "ReportRetrieveDescriptionInterface",
        };
      dispatch({
        type: "RETRIEVE_REPORT_DESCRIPTION",
        payload: reportRetrieveDescriptionData,
      });
      setRetrieveReportDescription(false);
    }
  }, [retrieveReportDescription]);

  useEffect(() => {
    if (downloadReport === true) {
      const downloadReportData: DownloadReportInterface = {
        email: user.email,
        reportID: selectedReport.reportID,
        projectID: user.openProject?.projectID!,
        projectUserRole: user.openProject?.projectUserRole!,
        type: "DownloadReportInterface",
      };
      const decision: DecisionInterface = {
        title: "Download Report",
        message:
          "You have selected a report to download. Are you sure you want to continue?",
        decisionOptions: ["DOWNLOAD_REPORT", "NO_ACTION"],
        result: "",
        data: downloadReportData,
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setDownloadReport(false);
  }, [downloadReport]);

  return (
    <>
      {(() => {
        if (selectedReport) {
          return (
            <div className="itemDetails">
              <span> Name: {selectedReport.reportName} </span>
              <span> Created By: {selectedReport.reportUploadedBy} </span>
              <span>
                Creation Date:{" "}
                {FormatDateFE(selectedReport.reportCreationDate!)}
              </span>
              <span> Clash Count: {selectedReport.reportClashCount} </span>
              <span> Size: {BytesToMB(selectedReport.reportSize)} </span>
              <div className="horizontalDetailsOptionWrapper">
                <div className="menuButton">
                  <img
                    className="icon-small"
                    src="assets/icons/view-report.png"
                    alt="OPEN"
                    onClick={() => {
                      setOpenReport(true);
                    }}
                  />
                </div>
                <div className="menuButton">
                  <img
                    className="icon-small"
                    src="assets/icons/write-text.png"
                    alt="DESCRIPTION"
                    onClick={() => {
                      setRetrieveReportDescription(true);
                    }}
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setDownloadReport(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/download.png"
                    alt="DOWNLOAD"
                  />
                </div>
                {user.openProject?.projectUserRole !== "USER" ? (
                  <div
                    className="menuButton"
                    onClick={() => {
                      setDeleteReportDialogReveal(true);
                    }}
                  >
                    <img
                      className="icon-small"
                      src="assets/icons/delete.png"
                      alt="DELETE"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ReportInfo;
