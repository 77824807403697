import { FC } from "react";
import ReportElement from "./report-element";
import { ReportInterface } from "../../../middleware/interfaces/report/report-interface";

interface reportContentProps {
  report: ReportInterface;
  userInputElementCount: number;
}

const ReportContent: FC<reportContentProps> = ({
  report,
  userInputElementCount: userInputElementCount,
}) => {
  const headerReportElement = report.reportElements?.find(
    (element) => element.reportElementName === "logo"
  );

  const bodyReportElements = report.reportElements?.filter(
    (element) => element.reportElementName !== "logo"
  );

  const RenderedReportElements = bodyReportElements?.map(
    (reportElement, index) => {
      const reportElementGroup =
        report.reportContentFilter?.reportElementGroupData.find(
          (element) =>
            element.reportElementGroupName ===
            reportElement.reportElementGroupName
        );

      return (
        <ReportElement
          key={index}
          reportElement={reportElement}
          userInputElementCount={userInputElementCount}
          filter={
            reportElementGroup!.reportElementGroupData.reportElementGroupFilter
          }
        />
      );
    }
  );

  return (
    <div id="reportWrapper">
      {headerReportElement && (
        <div id="reportHeader">
          <img
            src={headerReportElement.reportElementImageUrl}
            alt={`Report ${headerReportElement.reportElementID.toString()}`}
          />
        </div>
      )}
      <div id="reportBody">
        {bodyReportElements?.length ? (
          RenderedReportElements
        ) : (
          <p>No report elements available.</p>
        )}
      </div>
    </div>
  );
};

export default ReportContent;
