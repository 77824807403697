import { FC, useEffect, useState } from "react";
import {
  HandleLogOut,
  HandleReturn,
  SaveUserToLocalStorage,
  StopEventPropagation,
  UpdateUserLastActionDate,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import { Navigate } from "react-router-dom";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";
import ReportContent from "../ui/report/report-content";
import MenuButton from "../ui/user/menu-button";
import "../../styles/report-viewer.css";
import ReportContentFilterDialog from "../ui/report/report-content-filter-dialog";

export const ReportViewer: FC = () => {
  const [state, dispatch] = useAppStateContext();
  const [reportMaximize, setReportMaximize] = useState(false);
  const [reportContentfilterDialogReveal, setReportContentfilterDialogReveal] =
    useState(false);
  const [userInputWidthFactor, setUserInputWidthFactor] = useState(2);

  const HandleReportDoubleClick = (event: React.MouseEvent) => {
    StopEventPropagation(event);
    setReportMaximize(!reportMaximize);
  };

  const SetReportElementDataStyle = () => {
    if (reportMaximize) {
      return {
        "--ContentWidth": "100vw",
        "--ContentHeight": "100vh",
      } as React.CSSProperties;
    } else {
      return {
        "--ContentWidth": "60vw",
        "--ContentHeight": "calc(95vh - 2 * var(--NavAndFooterHeight))",
      } as React.CSSProperties;
    }
  };

  useEffect(() => {
    // Define a named function to handle beforeunload event
    const HandleBeforeUnload = () => {
      SaveUserToLocalStorage(state.user);
    };
    window.addEventListener("beforeunload", HandleBeforeUnload);
    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", HandleBeforeUnload);
    };
  }, [state.user]);

  return (
    <>
      {(() => {
        if (state.user?.openReport) {
          return (
            <div
              id="pageWrapper"
              onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
            >
              {!reportMaximize && <MainNavigationPanel user={state.user} />}
              <div id="contentWrapper">
                {!reportMaximize && (
                  <div id="leftMenu">
                    <div className="menuButtonWrapper">
                      <MenuButton
                        imageSource="assets/icons/maximize.png"
                        alternativeText="MAXIMIZE"
                        dispatch={() => {
                          console.log("MAXIMIZE!");
                        }}
                      />
                      <MenuButton
                        imageSource="assets/icons/filter.png"
                        alternativeText="FILTER"
                        dispatch={() => {
                          setReportContentfilterDialogReveal(true);
                        }}
                      />
                      <MenuButton
                        imageSource="assets/icons/return.png"
                        alternativeText="RETURN"
                        dispatch={() => {
                          HandleReturn(state, dispatch);
                        }}
                      />
                      <MenuButton
                        imageSource="assets/icons/logout.png"
                        alternativeText="LOG OUT"
                        dispatch={() => {
                          HandleLogOut(dispatch);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div
                  id="content"
                  onDoubleClick={HandleReportDoubleClick}
                  style={SetReportElementDataStyle()}
                >
                  <ReportContent
                    report={state.openReport!}
                    userInputElementCount={userInputWidthFactor}
                  />
                  <ReportContentFilterDialog
                    reveal={reportContentfilterDialogReveal}
                    state={state}
                    setReportContentfilterDialogReveal={
                      setReportContentfilterDialogReveal
                    }
                    dispatch={dispatch}
                  />
                </div>
                {!reportMaximize && <div id="rightMenu"></div>}
              </div>
              {!reportMaximize && <MainFooterPanel />}
            </div>
          );
        } else {
          return <Navigate to={"/authentication&registration"} />;
        }
      })()}
    </>
  );
};
